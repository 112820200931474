/* eslint-disable @typescript-eslint/no-var-requires */
const { doggyDayCareService, homeDogBoardingService, dogSittingService, petSittingService } = require('./Service');

const { BE, CA, CH, DE, FR, LU, NL } = require('./Locale/Country');

const {
    EnglishBelgium,
    GermanBelgium,
    FrenchBelgium,
    DutchBelgium,
    GermanSwitzerland,
    FrenchSwitzerland,
    ItalianSwitzerland,
    GermanLuxembourg,
    FrenchLuxembourg,
    EnglishLuxembourg,
    FrenchCanada,
    EnglishCanada,
    EnglishGermany,
    EnglishFrance,
    FrenchFrance,
    EnglishNetherlands,
    DutchNetherlands,
    GermanAustria,
    EnglishNewZealand,
    EnglishGreatBritain,
    EnglishAustralia,
    EnglishHongKong,
    EnglishSingapore,
    GermanGermany,
    DanishDenmark,
    FinnishFinland,
    EnglishIreland,
    ItalianItaly,
    EnglishJapan,
    NorwegianNorway,
    SwedishSweden,
} = require('./Locale/Locale');

const httpsDePawshakeCh = 'https://de.pawshake.ch';
const httpsFrPawshakeCh = 'https://fr.pawshake.ch';
const httpsItPawshakeCh = 'https://it.pawshake.ch';
const httpsEnPawshakeLu = 'https://en.pawshake.lu';
const httpsDePawshakeLu = 'https://de.pawshake.lu';
const httpsFrPawshakeLu = 'https://fr.pawshake.lu';
const httpsEnPawshakeBe = 'https://en.pawshake.be';
const httpsDePawshakeBe = 'https://de.pawshake.be';
const httpsNlPawshakeBe = 'https://nl.pawshake.be';
const httpsFrPawshakeBe = 'https://fr.pawshake.be';
const httpsEnPawshakeCa = 'https://en.pawshake.ca';
const httpsFrPawshakeCa = 'https://fr.pawshake.ca';
const httpsEnPawshakeDe = 'https://en.pawshake.de';
const httpsWwwPawshakeDe = 'https://www.pawshake.de';
const httpsEnPawshakeFr = 'https://en.pawshake.fr';
const httpsWwwPawshakeFr = 'https://www.pawshake.fr';
const httpsEnPawshakeNl = 'https://en.pawshake.nl';
const httpsWwwPawshakeNl = 'https://www.pawshake.nl';
const httpsWwwPawshakeAt = 'https://www.pawshake.at';
const httpsWwwPawshakeCoNz = 'https://www.pawshake.co.nz';
const httpsWwwPawshakeCoUk = 'https://www.pawshake.co.uk';
const httpsWwwPawshakeComAu = 'https://www.pawshake.com.au';
const httpsWwwPawshakeComHk = 'https://www.pawshake.com.hk';
const httpsWwwPawshakeComSg = 'https://www.pawshake.com.sg';
const httpsWwwPawshakeDk = 'https://www.pawshake.dk';
const httpsWwwPawshakeFi = 'https://www.pawshake.fi';
const httpsWwwPawshakeIe = 'https://www.pawshake.ie';
const httpsWwwPawshakeIt = 'https://www.pawshake.it';
const httpsWwwPawshakeJp = 'https://www.pawshake.jp';
const httpsWwwPawshakeNo = 'https://www.pawshake.no';
const httpsWwwPawshakeSe = 'https://www.pawshake.se';

const localBrandNames = {
    'https://en.pawshake.be': 'Pawshake.be',
    'https://de.pawshake.be': 'Pawshake.be',
    'https://nl.pawshake.be': 'Pawshake.be',
    'https://fr.pawshake.be': 'Pawshake.be',
    'https://de.pawshake.ch': 'Pawshake.ch',
    'https://fr.pawshake.ch': 'Pawshake.ch',
    'https://it.pawshake.ch': 'Pawshake.ch',
    'https://en.pawshake.lu': 'Pawshake.lu',
    'https://fr.pawshake.lu': 'Pawshake.lu',
    'https://de.pawshake.lu': 'Pawshake.lu',
    'https://en.pawshake.ca': 'Pawshake.ca',
    'https://fr.pawshake.ca': 'Pawshake.ca',
    'https://en.pawshake.fr': 'Pawshake.fr',
    'https://www.pawshake.fr': 'Pawshake.fr',
    'https://en.pawshake.nl': 'Pawshake.nl',
    'https://www.pawshake.nl': 'Pawshake.nl',
    'https://www.pawshake.at': 'Pawshake.at',
    'https://www.pawshake.co.nz': 'Pawshake.co.nz',
    'https://www.pawshake.co.uk': 'Pawshake.co.uk',
    'https://www.pawshake.com.au': 'Pawshake.com.au',
    'https://www.pawshake.com.hk': 'Pawshake.com.hk',
    'https://www.pawshake.com.sg': 'Pawshake.com.sg',
    'https://en.pawshake.de': 'Pawshake.de',
    'https://www.pawshake.de': 'Pawshake.de',
    'https://www.pawshake.dk': 'Pawshake.dk',
    'https://www.pawshake.fi': 'Pawshake.fi',
    'https://www.pawshake.ie': 'Pawshake.ie',
    'https://www.pawshake.it': 'Pawshake.it',
    'https://www.pawshake.jp': 'Pawshake.jp',
    'https://www.pawshake.no': 'Pawshake.no',
    'https://www.pawshake.se': 'Pawshake.se',
};

const shortDomains = {
    'https://en.pawshake.be': 'en_be',
    'https://de.pawshake.be': 'de_be',
    'https://nl.pawshake.be': 'nl_be',
    'https://fr.pawshake.be': 'fr_be',
    'https://de.pawshake.ch': 'de_ch',
    'https://fr.pawshake.ch': 'fr_ch',
    'https://it.pawshake.ch': 'it_ch',
    'https://en.pawshake.lu': 'en_lu',
    'https://fr.pawshake.lu': 'fr_lu',
    'https://de.pawshake.lu': 'de_lu',
    'https://en.pawshake.ca': 'en_ca',
    'https://fr.pawshake.ca': 'fr_ca',
    'https://en.pawshake.fr': 'en_fr',
    'https://www.pawshake.fr': 'fr_fr',
    'https://en.pawshake.nl': 'en_nl',
    'https://www.pawshake.nl': 'nl_nl',
    'https://www.pawshake.at': 'de_at',
    'https://www.pawshake.co.nz': 'en_nz',
    'https://www.pawshake.co.uk': 'en_gb',
    'https://www.pawshake.com.au': 'en_au',
    'https://www.pawshake.com.hk': 'en_hk',
    'https://www.pawshake.com.sg': 'en_sg',
    'https://en.pawshake.de': 'en_de',
    'https://www.pawshake.de': 'de_de',
    'https://www.pawshake.dk': 'da_dk',
    'https://www.pawshake.fi': 'fi_fi',
    'https://www.pawshake.ie': 'en_ie',
    'https://www.pawshake.it': 'it_it',
    'https://www.pawshake.jp': 'en_jp',
    'https://www.pawshake.no': 'no_no',
    'https://www.pawshake.se': 'sv_se',
};

const locales = {
    'https://en.pawshake.be': EnglishBelgium,
    'https://de.pawshake.be': GermanBelgium,
    'https://fr.pawshake.be': FrenchBelgium,
    'https://nl.pawshake.be': DutchBelgium,
    'https://de.pawshake.ch': GermanSwitzerland,
    'https://fr.pawshake.ch': FrenchSwitzerland,
    'https://it.pawshake.ch': ItalianSwitzerland,
    'https://de.pawshake.lu': GermanLuxembourg,
    'https://fr.pawshake.lu': FrenchLuxembourg,
    'https://en.pawshake.lu': EnglishLuxembourg,
    'https://fr.pawshake.ca': FrenchCanada,
    'https://en.pawshake.ca': EnglishCanada,
    'https://en.pawshake.de': EnglishGermany,
    'https://en.pawshake.fr': EnglishFrance,
    'https://www.pawshake.fr': FrenchFrance,
    'https://en.pawshake.nl': EnglishNetherlands,
    'https://www.pawshake.nl': DutchNetherlands,
    'https://www.pawshake.at': GermanAustria,
    'https://www.pawshake.co.nz': EnglishNewZealand,
    'https://www.pawshake.co.uk': EnglishGreatBritain,
    'https://www.pawshake.com.au': EnglishAustralia,
    'https://www.pawshake.com.hk': EnglishHongKong,
    'https://www.pawshake.com.sg': EnglishSingapore,
    'https://www.pawshake.de': GermanGermany,
    'https://www.pawshake.dk': DanishDenmark,
    'https://www.pawshake.fi': FinnishFinland,
    'https://www.pawshake.ie': EnglishIreland,
    'https://www.pawshake.it': ItalianItaly,
    'https://www.pawshake.jp': EnglishJapan,
    'https://www.pawshake.no': NorwegianNorway,
    'https://www.pawshake.se': SwedishSweden,
};

const cloudfrontRegions = {
    'en-BE': httpsEnPawshakeBe,
    'de-BE': httpsDePawshakeBe,
    'fr-BE': httpsFrPawshakeBe,
    'nl-BE': httpsNlPawshakeBe,
    'de-CH': httpsDePawshakeCh,
    'fr-CH': httpsFrPawshakeCh,
    'it-CH': httpsItPawshakeCh,
    'de-LU': httpsDePawshakeLu,
    'fr-LU': httpsFrPawshakeLu,
    'en-LU': httpsEnPawshakeLu,
    'fr-CA': httpsFrPawshakeCa,
    'en-CA': httpsEnPawshakeCa,
    'en-DE': httpsEnPawshakeDe,
    'en-FR': httpsEnPawshakeFr,
    'fr-FR': httpsWwwPawshakeFr,
    'en-NL': httpsEnPawshakeNl,
    'nl-NL': httpsWwwPawshakeNl,
    'de-AT': httpsWwwPawshakeAt,
    'en-NZ': httpsWwwPawshakeCoNz,
    'en-GB': httpsWwwPawshakeCoUk,
    'en-AU': httpsWwwPawshakeComAu,
    'en-HK': httpsWwwPawshakeComHk,
    'en-SG': httpsWwwPawshakeComSg,
    'de-DE': httpsWwwPawshakeDe,
    'da-DK': httpsWwwPawshakeDk,
    'fi-FI': httpsWwwPawshakeFi,
    'en-IE': httpsWwwPawshakeIe,
    'it-IT': httpsWwwPawshakeIt,
    'en-JP': httpsWwwPawshakeJp,
    'no-NO': httpsWwwPawshakeNo,
    'sv-SE': httpsWwwPawshakeSe,
};

const getDomainBasedUponLocale = (locale) => {
    return cloudfrontRegions[locale.toString()];
};

const getDomainBasedUponLocaleString = (localeString) => {
    return cloudfrontRegions[localeString];
};

const getDomainBasedUponCloudfrontRegion = (cloudFrontRegion) => {
    if (cloudfrontRegions.hasOwnProperty(cloudFrontRegion)) {
        return cloudfrontRegions[cloudFrontRegion];
    }
    return httpsEnPawshakeBe;
};

const getShortDomainOf = (domain) => {
    if (shortDomains.hasOwnProperty(domain)) {
        return shortDomains[domain];
    }
    return 'en_be';
};

const getLocalBrandNameOf = (domain) => {
    if (localBrandNames.hasOwnProperty(domain)) {
        return localBrandNames[domain];
    }
    return 'Pawshake';
};

const getLocaleOf = (domain) => {
    if (locales.hasOwnProperty(domain)) {
        return locales[domain];
    }
    return EnglishBelgium;
};

const getCountryOf = (domain) => {
    const localeOf = getLocaleOf(domain);
    return localeOf.getCountry();
};

const getLanguageOf = (domain) => {
    const localeOf = getLocaleOf(domain);
    return localeOf.getLanguage();
};

const countryHasDifferentLanguages = (country) => {
    return [BE, CH, LU, CA, DE, FR, NL].includes(country);
};

const getDefaultIndexableSearchableService = (domain) => {
    if (domain === httpsWwwPawshakeComHk) {
        return petSittingService;
    }

    if (domain === httpsWwwPawshakeIt) {
        return dogSittingService;
    }

    if (
        [httpsFrPawshakeCa, httpsFrPawshakeLu, httpsFrPawshakeBe, httpsFrPawshakeCh, httpsWwwPawshakeFr].includes(
            domain
        )
    ) {
        return doggyDayCareService;
    }

    return homeDogBoardingService;
};

const Locales = [
    EnglishBelgium,
    GermanBelgium,
    FrenchBelgium,
    DutchBelgium,
    GermanSwitzerland,
    FrenchSwitzerland,
    ItalianSwitzerland,
    GermanLuxembourg,
    FrenchLuxembourg,
    EnglishLuxembourg,
    FrenchCanada,
    EnglishCanada,
    EnglishGermany,
    EnglishFrance,
    FrenchFrance,
    EnglishNetherlands,
    DutchNetherlands,
    GermanAustria,
    EnglishNewZealand,
    EnglishGreatBritain,
    EnglishAustralia,
    EnglishHongKong,
    EnglishSingapore,
    GermanGermany,
    DanishDenmark,
    FinnishFinland,
    EnglishIreland,
    ItalianItaly,
    EnglishJapan,
    NorwegianNorway,
    SwedishSweden,
];

const Domains = [
    httpsWwwPawshakeComAu,
    httpsWwwPawshakeAt,
    httpsDePawshakeBe,
    httpsEnPawshakeBe,
    httpsFrPawshakeBe,
    httpsNlPawshakeBe,
    httpsEnPawshakeCa,
    httpsFrPawshakeCa,
    httpsWwwPawshakeDk,
    httpsWwwPawshakeFi,
    httpsEnPawshakeFr,
    httpsWwwPawshakeFr,
    httpsWwwPawshakeDe,
    httpsEnPawshakeDe,
    httpsWwwPawshakeComHk,
    httpsWwwPawshakeIe,
    httpsWwwPawshakeIt,
    httpsWwwPawshakeJp,
    httpsDePawshakeLu,
    httpsEnPawshakeLu,
    httpsFrPawshakeLu,
    httpsWwwPawshakeCoNz,
    httpsWwwPawshakeNo,
    httpsWwwPawshakeComSg,
    httpsWwwPawshakeSe,
    httpsDePawshakeCh,
    httpsItPawshakeCh,
    httpsFrPawshakeCh,
    httpsEnPawshakeNl,
    httpsWwwPawshakeNl,
    httpsWwwPawshakeCoUk,
];

module.exports = {
    httpsEnPawshakeBe,
    httpsDePawshakeBe,
    httpsNlPawshakeBe,
    httpsFrPawshakeBe,
    httpsEnPawshakeCa,
    httpsFrPawshakeCa,
    httpsDePawshakeCh,
    httpsFrPawshakeCh,
    httpsItPawshakeCh,
    httpsEnPawshakeLu,
    httpsDePawshakeLu,
    httpsFrPawshakeLu,
    httpsEnPawshakeDe,
    httpsWwwPawshakeDe,
    httpsEnPawshakeFr,
    httpsWwwPawshakeFr,
    httpsEnPawshakeNl,
    httpsWwwPawshakeNl,
    httpsWwwPawshakeAt,
    httpsWwwPawshakeCoNz,
    httpsWwwPawshakeCoUk,
    httpsWwwPawshakeComAu,
    httpsWwwPawshakeComHk,
    httpsWwwPawshakeComSg,
    httpsWwwPawshakeDk,
    httpsWwwPawshakeFi,
    httpsWwwPawshakeIe,
    httpsWwwPawshakeIt,
    httpsWwwPawshakeJp,
    httpsWwwPawshakeNo,
    httpsWwwPawshakeSe,
    Domains,
    Locales,
    getShortDomainOf,
    getLocalBrandNameOf,
    getLocaleOf,
    getCountryOf,
    getLanguageOf,
    getDomainBasedUponCloudfrontRegion,
    getDomainBasedUponLocale,
    getDomainBasedUponLocaleString,
    getDefaultIndexableSearchableService,
    countryHasDifferentLanguages,
};
