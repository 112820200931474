import React from 'react';

const {
    English,
    Danish,
    Dutch,
    Finnish,
    French,
    German,
    Italian,
    Norwegian,
    Swedish,
} = require('../Pawshake/Core/Locale/Language');

const {
    EnglishBelgium,
    DutchBelgium,
    GermanBelgium,
    FrenchBelgium,
    ItalianSwitzerland,
    FrenchSwitzerland,
    GermanSwitzerland,
    FrenchLuxembourg,
    EnglishLuxembourg,
    GermanLuxembourg,
    EnglishCanada,
    FrenchCanada,
    GermanGermany,
    EnglishGermany,
    FrenchFrance,
    EnglishFrance,
    EnglishNetherlands,
    DutchNetherlands,
    GermanAustria,
    EnglishNewZealand,
    EnglishGreatBritain,
    EnglishAustralia,
    EnglishSingapore,
    EnglishIreland,
    EnglishHongKong,
    SwedishSweden,
    NorwegianNorway,
    DanishDenmark,
    FinnishFinland,
    ItalianItaly,
    EnglishJapan,
} = require('../Pawshake/Core/Locale/Locale');

const {
    BE,
    LU,
    AT,
    AU,
    CA,
    CH,
    DE,
    DK,
    FI,
    FR,
    GB,
    HK,
    IE,
    IT,
    JP,
    NL,
    NO,
    NZ,
    SE,
    SG,
} = require('../Pawshake/Core/Locale/Country');

const {
    httpsEnPawshakeBe,
    httpsDePawshakeBe,
    httpsFrPawshakeBe,
    httpsNlPawshakeBe,
    httpsDePawshakeCh,
    httpsFrPawshakeCh,
    httpsItPawshakeCh,
    httpsDePawshakeLu,
    httpsEnPawshakeLu,
    httpsFrPawshakeLu,
    httpsFrPawshakeCa,
    httpsEnPawshakeCa,
    httpsEnPawshakeDe,
    httpsWwwPawshakeDe,
    httpsEnPawshakeFr,
    httpsWwwPawshakeFr,
    httpsWwwPawshakeNl,
    httpsEnPawshakeNl,
    httpsWwwPawshakeAt,
    httpsWwwPawshakeCoNz,
    httpsWwwPawshakeCoUk,
    httpsWwwPawshakeComAu,
    httpsWwwPawshakeComSg,
    httpsWwwPawshakeComHk,
    httpsWwwPawshakeDk,
    httpsWwwPawshakeFi,
    httpsWwwPawshakeIe,
    httpsWwwPawshakeIt,
    httpsWwwPawshakeJp,
    httpsWwwPawshakeNo,
    httpsWwwPawshakeSe,
} = require('../Pawshake/Core/Domain');

const settings = {
    'en-BE': {
        domain: httpsEnPawshakeBe,
        country: BE,
        locale: EnglishBelgium,
        language: English,
        zendeskLanguage: 'en-be',
    },
    'de-BE': {
        domain: httpsDePawshakeBe,
        country: BE,
        locale: GermanBelgium,
        language: German,
        zendeskLanguage: German,
    },
    'fr-BE': {
        domain: httpsFrPawshakeBe,
        country: BE,
        locale: FrenchBelgium,
        language: French,
        zendeskLanguage: French,
    },
    'nl-BE': {
        domain: httpsNlPawshakeBe,
        country: BE,
        locale: DutchBelgium,
        language: Dutch,
        zendeskLanguage: Dutch,
    },
    'de-CH': {
        domain: httpsDePawshakeCh,
        country: CH,
        locale: GermanSwitzerland,
        language: German,
        zendeskLanguage: German,
    },
    'fr-CH': {
        domain: httpsFrPawshakeCh,
        country: CH,
        locale: FrenchSwitzerland,
        language: French,
        zendeskLanguage: French,
    },
    'it-CH': {
        domain: httpsItPawshakeCh,
        country: CH,
        locale: ItalianSwitzerland,
        language: Italian,
        zendeskLanguage: Italian,
    },
    'de-LU': {
        domain: httpsDePawshakeLu,
        country: LU,
        locale: GermanLuxembourg,
        language: German,
        zendeskLanguage: German,
    },
    'fr-LU': {
        domain: httpsFrPawshakeLu,
        country: LU,
        locale: FrenchLuxembourg,
        language: French,
        zendeskLanguage: French,
    },
    'en-LU': {
        domain: httpsEnPawshakeLu,
        country: LU,
        locale: EnglishLuxembourg,
        language: English,
        zendeskLanguage: 'en-be',
    },
    'fr-CA': {
        domain: httpsFrPawshakeCa,
        country: CA,
        locale: FrenchCanada,
        language: French,
        zendeskLanguage: 'fr-ca',
    },
    'en-CA': {
        domain: httpsEnPawshakeCa,
        country: CA,
        locale: EnglishCanada,
        language: English,
        zendeskLanguage: 'en-ca',
    },
    'en-DE': {
        domain: httpsEnPawshakeDe,
        country: DE,
        locale: EnglishGermany,
        language: English,
        zendeskLanguage: 'en-be',
    },
    'de-DE': {
        domain: httpsWwwPawshakeDe,
        country: DE,
        locale: GermanGermany,
        language: German,
        zendeskLanguage: German,
    },
    'en-FR': {
        domain: httpsEnPawshakeFr,
        country: FR,
        locale: EnglishFrance,
        language: English,
        zendeskLanguage: 'en-be',
    },
    'fr-FR': {
        domain: httpsWwwPawshakeFr,
        country: FR,
        locale: FrenchFrance,
        language: French,
        zendeskLanguage: French,
    },
    'en-NL': {
        domain: httpsEnPawshakeNl,
        country: NL,
        locale: EnglishNetherlands,
        language: Dutch,
        zendeskLanguage: 'en-be',
    },
    'nl-NL': {
        domain: httpsWwwPawshakeNl,
        country: NL,
        locale: DutchNetherlands,
        language: Dutch,
        zendeskLanguage: Dutch,
    },
    'de-AT': {
        domain: httpsWwwPawshakeAt,
        country: AT,
        locale: GermanAustria,
        language: German,
        zendeskLanguage: German,
    },
    'en-NZ': {
        domain: httpsWwwPawshakeCoNz,
        country: NZ,
        locale: EnglishNewZealand,
        language: English,
        zendeskLanguage: 'en-nz',
    },
    'en-GB': {
        domain: httpsWwwPawshakeCoUk,
        country: GB,
        locale: EnglishGreatBritain,
        language: English,
        zendeskLanguage: 'en-gb',
    },
    'en-AU': {
        domain: httpsWwwPawshakeComAu,
        country: AU,
        locale: EnglishAustralia,
        language: English,
        zendeskLanguage: 'en-au',
    },
    'en-HK': {
        domain: httpsWwwPawshakeComHk,
        country: HK,
        locale: EnglishHongKong,
        language: English,
        zendeskLanguage: 'en-sg',
    },
    'en-SG': {
        domain: httpsWwwPawshakeComSg,
        country: SG,
        locale: EnglishSingapore,
        language: English,
        zendeskLanguage: 'en-sg',
    },
    'da-DK': {
        domain: httpsWwwPawshakeDk,
        country: DK,
        locale: DanishDenmark,
        language: Danish,
        zendeskLanguage: Danish,
    },
    'fi-FI': {
        domain: httpsWwwPawshakeFi,
        country: FI,
        locale: FinnishFinland,
        language: Finnish,
        zendeskLanguage: Finnish,
    },
    'en-IE': {
        domain: httpsWwwPawshakeIe,
        country: IE,
        locale: EnglishIreland,
        language: English,
        zendeskLanguage: 'en-ie',
    },
    'it-IT': {
        domain: httpsWwwPawshakeIt,
        country: IT,
        locale: ItalianItaly,
        language: Italian,
        zendeskLanguage: Italian,
    },
    'en-JP': {
        domain: httpsWwwPawshakeJp,
        country: JP,
        locale: EnglishJapan,
        language: English,
        zendeskLanguage: English,
    },
    'no-NO': {
        domain: httpsWwwPawshakeNo,
        country: NO,
        locale: NorwegianNorway,
        language: Norwegian,
        zendeskLanguage: Norwegian,
    },
    'sv-SE': {
        domain: httpsWwwPawshakeSe,
        country: SE,
        locale: SwedishSweden,
        language: Swedish,
        zendeskLanguage: Swedish,
    },
};

export const getApplicationContextForCloudFrontRegion = (cloudfrontRegion) => {
    return settings[cloudfrontRegion];
};

export const ApplicationContext = React.createContext({});

export function withApplicationAwareness(Component) {
    const applicationAwareComponent = function ApplicationAwareComponent(props) {
        return (
            <ApplicationContext.Consumer>
                {(application) => <Component {...props} application={application} />}
            </ApplicationContext.Consumer>
        );
    };

    applicationAwareComponent.getInitialProps = Component.getInitialProps;

    return applicationAwareComponent;
}
